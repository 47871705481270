@import '../../../styles/variables';

.drive-page__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.drive-page__title {
  //font-size: 22px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: $lg * 1.5;
}

.drive-page__body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .drive-tree__container {
    padding-right: $lg;
    min-width: 360px;
  }

  .drive-page__main {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: $lg;

    > div:first-child {
      display: flex;
      margin-bottom: $lg;

      > div {
        flex: 1;

        &:first-child {
          margin-right: $lg;
        }
        &:last-child {
          margin-left: $lg;
        }
      }
    }

    .drive-search__container {
      max-width: 400px;
      display: flex;

      button {
        margin-left: $md;
      }
    }
  }
}

