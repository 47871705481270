// Colors
$color-main: #00aeef;
$color-dark: #202124;
$color-white: #ffffff;
$color-black: #000000;
$color-grey-darker: #a7a7a7;
//$color-grey-dark: #c6c8c9;/
$color-grey-dark: #5f6368;
$color-grey: #eeeeee;
$color-grey-light: #f9f9f9;
$color-silver: #f9f9f9;
$color-success: #15aa1a;
$color-danger: #F53434;
$color-red: #EF0000;
$color-warning: #f97032;

$color-request: #F58634;
$color-booked: #81B214;
$color-overdue: #F53434;
$color-completed: #00AEEF;

// Spacing
$sm: 4px;
$md: 8px;
$lg: 16px;

// Breakpoints
$screen-xs: 0px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

// Font Size
$font-size-xs: 8px;
$font-size-sm: 10px;
$font-size-md: 14px;
$font-size-bg: 16px;
$font-size-lg: 28px;
