@import '../../styles/variables';

.email-triggers__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  padding: $lg * 1.5;

  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;

  .emails-list__container {
    flex: 1;
    margin-top: $md;
    padding: $lg;
    min-height: 100px;

    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $lg;

    .case-note {
      margin-bottom: $lg;
    }
  }
}
