@import '../../styles/variables';

.case-date-range__container {
  width: 30%;
  margin-left: $lg * 2;

  padding: $lg * 1.5;
  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;

  .case-date-range {
    padding-left: $lg;
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $lg;
  }
}

.filters-list__container {
  display: flex;
  align-items: center;
  margin: $sm 0;

  .filters-list__item {
    padding: $sm $md * 1.5 $sm $md * 2.5;
    margin-left: $md;
    font-size: $font-size-md;
    font-weight: bold;
    text-align: center;
    background: $color-grey;
    color: $color-grey-dark;
    border: 1px solid $color-grey-dark;
    border-radius: $lg;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
      margin-left: $md;
    }
  }
}

.item-reporting__container, .usage-reporting__container {
  padding: $lg * 1.5;
  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;

  > .item-reporting-form {
    display: flex;

    .case-date-range {
      padding-left: $lg;
      background: $color-white;
      border: 1px solid $color-grey;
      border-radius: $lg;
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:not(:first-child) {
        margin-left: $md;
      }

      &:not(:last-child) {
        margin-right: $md;
      }
    }
  }

  > .usage-reporting-form {
    display: flex;

    .case-date-range {
      padding-left: $lg;
      background: $color-white;
      border: 1px solid $color-grey;
      border-radius: $lg;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:first-child {
        flex: 0.5;
        max-width: 50%;
        margin-right: $md;
      }

      &:nth-child(2) {
        flex: 0.25;
        max-width: 25%;
        margin-left: $md;
        margin-right: $md;
      }

      &:last-child {
        flex: 0.25;
        max-width: 25%;
        margin-left: $md;
      }

      .MuiFormControl-root {
        margin-bottom: 0;
      }
    }
  }

  .item-reporting-actions, .usage-reporting-actions {
    margin-top: $lg * 1.5;
    display: flex;
    justify-content: flex-end;
  }
}

.report-modal-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 62px;
  background-color: $color-white;
  cursor: pointer;

  border-radius: 16px;
  border: 1.5px solid $color-grey;
  padding: $md $md $md $lg;

  .modal-select-placeholder {
    padding-left: $sm;
    font-weight: normal;
    color: #999999;
  }

  .modal-select-list {
    font-weight: bold;
  }
}
