@import '../../../styles/variables';

.confirmation-modal__container {
  max-width: 100%;

  background-color: $color-grey-light;
  border: 1.5px solid $color-grey;
  border-radius: 30px;

  padding: $lg * 1.5;

  @media screen and (max-width: $screen-sm) {
    height: 100%;
  }
}

.modal-title {
  font-size: 18px;
  font-weight: 800;
  color: $color-dark;
  margin-bottom: $lg;
  text-align: center;
}

.modal-content {
  margin-top: $lg * 2;
  margin-bottom: $lg * 2;
}

.upload-modal__container {
  padding: 0 $lg * 1.5 $lg * 1.5;

  .upload-modal__actions {
    margin-top: $lg;
    display: flex;
    justify-content: space-between;
  }
}
