@import '../../styles/variables';

.addresses-input__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 62px;
  background-color: $color-white;
  cursor: pointer;

  border-radius: 16px;
  border: 1.5px solid $color-grey;
  padding: $md $md * 1.5 $md $lg;
}

.addresses-input__list {
  font-weight: bold;
  word-break: break-word;
}

.addresses-input__label {
  padding-left: $sm;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-family: 'Inter', 'Montserrat', sans-serif;

  > span {
    margin-right: 6px;
    color: $color-grey-darker;
    font-weight: normal;
  }
}

.hospital-addresses__modal {
  min-width: 590px !important;

  .MuiFormControlLabel-root {
    margin-right: 0 !important;
  }
}
