@import '../../styles/variables';

.drive-breadcrumbs__container {
  height: 62px;
  width: 100%;
  padding: 0 $lg;

  display: flex;
  align-items: center;

  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;

  .drive-breadcrumbs__item {
    color: $color-dark;
    cursor: pointer;
    font-weight: bold;
    //font-size: 12px;
  }
}

.drive-files-list__container {
  margin-top: $lg;
  display: flex;
  flex-direction: column;
  align-items: center;

  .drive-files-item {
    display: flex;
    align-items: center;

    width: 100%;
    height: 60px;
    padding: $md;

    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $lg;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: $md;
    }

    .drive-files-item__name {
      margin-left: $md;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}

.drive-browse__container {
  padding: $lg * 1.5;

  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;

  display: flex;
  flex-direction: column;
  flex: 1;
}

.drive-folder__container {
  display: flex;
  margin-right: $lg;
  margin-bottom: $lg;
  border-radius: $lg;

  cursor: pointer;

  &:hover {
    .drive-folder-icon {
      background: #d8d8d8;
    }
  }

  > .drive-folder-icon {
    height: 100px;
    width: 100px;
    min-width: 100px;

    background: $color-grey;
    border-radius: $lg;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  > .drive-folder-name__container {
    margin-left: $md;
    padding: $md 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.drive-file__container {
  margin-right: $lg;
  margin-bottom: $lg;

  border: 1px solid $color-grey;
  border-radius: $lg;

  cursor: pointer;

  &:hover {
    .drive-file-icon {
      background: #d8d8d8;
    }
  }

  > .drive-file-icon {
    height: 200px;
    position: relative;

    background: $color-grey;
    border-radius: $lg $lg 0 0;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 200px;
      width: 100%;
      border-radius: $lg $lg 0 0;
      object-fit: cover;
    }

    > .drive-file-label {
      position: absolute;
      top: $lg;
      right: $lg;

      padding: $sm $md;
      font-size: $font-size-sm;

      display: flex;
      align-items: center;

      background: #c6c8c999;
      border-radius: $lg;
      color: $color-white;
    }
  }

  > .drive-file-name__container {
    padding: $lg;
    min-height: 112px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
