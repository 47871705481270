@import '../../../styles/variables';

.settings-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.settings-title {
  font-weight: 900;
  font-size: 22px;
  //font-size: 16px;

  margin-bottom: $sm * 2 + $lg;
}

.settings-body {
  background-color: $color-grey-light;
  padding-left: 2 * ($lg + $sm);
  padding-top: 2 * ($lg + $sm);

  display: inline-flex;
  flex-wrap: wrap;

  border: 1.5px solid $color-grey;
  border-radius: 30px;
}

.settings-option {
  --colorHover: $color-main;

  cursor: pointer;
  //width: 385px;
  height: 180px;

  display: flex;
  flex-direction: column;

  background-color: $color-white;
  border: 2px solid $color-grey;
  border-radius: 16px;
  margin-bottom: 2 * ($lg + $sm);
  margin-right: 2 * ($lg + $sm);

  padding: 2 * $lg $sm * 2 + $lg $sm * 2 + $lg 2 * $lg;

  &:hover {
    border-color: var(--colorHover);
  }

  .option-title {
    font-weight: 600;
    font-size: 18px;
    color: $color-dark;
    //font-size: 16px;
  }

  .option-sub-t {
    //font-weight: bold;
    color: $color-grey-dark;

    margin-top: $md;
    margin-bottom: 28px;
  }

  .option-icn {
    width: 36px;
    height: 36px;

    color: #1D1D1D;
  }

  .option-icn-cnt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
  }
}

.email-triggers-page__content {
  display: flex;

  > div {
    flex: 1;


    &:not(:first-child) {
      margin-left: $lg;
    }

    &:not(:last-child) {
      margin-right: $lg;
    }
  }
}

.company-page__container {
  .form__container {
    //max-width: 1200px;
  }
}

.bom-page__body {
  display: flex;

  > div:first-child {
    flex: 0.2;
  }

  > div:last-child {
    flex: 0.8;

    > div {
      display: flex;

      > div {
        flex: 1;

        &:first-child {
          margin-right: $md;
        }

        &:last-child {
          margin-left: $md;
        }
      }
    }

    .image-container {
      display: flex;
      max-height: 230px;
      align-items: center;
      justify-content: center;
      background: $color-white;
      border-radius: 30px;
      border: 1px solid $color-grey;
      position: relative;

      > img {
        padding: $lg * 1.5 $lg;
        max-height: 100%;
        width: auto;
        max-width: calc(100% - 32px);
      }

      .full-screen-icon {
        bottom: $lg;
        right: $lg;
        position: absolute;
        cursor: pointer;
      }
    }
  }
}
