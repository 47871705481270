@import '../../styles/variables';

.custom-decoding__container {
  height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: $lg * 2;
  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: 30px;

  .custom-decoding__title {
    color: $color-grey-dark;
    font-size: $font-size-bg;
    margin-bottom: $lg * 2;
  }

  .submit-button__container {
    display: flex;
    justify-content: flex-end;
  }

  .custom-decoding__header {
    display: flex;
    margin-bottom: $md;

    > div {
      color: $color-grey-dark;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;

      &:first-child {
        width: 70px;
        margin-right: $lg;
      }

      &:last-child {
        width: 164px;
      }
    }
  }

  .custom-decoding__item {
    margin-bottom: $lg;
    display: flex;
    height: 60px;

    .custom-decoding-values {
      > div {
        text-align: center;
        font-weight: 600;

        &:first-child {
          width: 70px;
          margin-right: $lg;
        }

        &:last-child {
          width: 164px;
        }
      }
    }

    .MuiFormControl-root {
      margin: 0;
    }

    .index-input__container {
      margin-right: $lg;

      .MuiInputBase-root {
        padding: 0;
        width: 70px;

        input {
          text-align: center;
        }
      }

    }

    .field-input__container {
      .MuiInputBase-root {
        width: 160px;
      }
    }

    .custom-decoding__actions {
      margin-left: $lg * 2;
      display: flex;

      > button {
        margin-right: $md;
      }
    }
  }
}
