@import '../../styles/variables';

.profile__company-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $sm;

  background: $color-white;
  border-radius: $md;
  border: 1px solid $color-grey;

  > .company-title-container {
    padding-left: $lg * 1.5;

    > .company-title {
      font-size: $font-size-bg;
      font-weight: 600;
    }

    > .company-review-label {
      font-size: 12px;
      color: $color-warning;
    }
  }

  > .company-image {
    height: 70px;
    width: 70px;
    border-radius: $md;
    border: 2px solid $color-grey;

    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-white;

    > img {
      width: 40px;
      height: auto;
      max-height: 40px;
      border-radius: $md;
    }

    > .company-letter {
      font-size: 30px;
      font-weight: bold;
    }
  }

  &.selected {
    background: $color-grey;
  }
}
