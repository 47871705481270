@import 'src/styles/variables';

.form__container {
  //min-width: 450px;
  max-width: 100%;

  background-color: $color-grey-light;
  border: 1.5px solid $color-grey;
  border-radius: 30px;

  padding: $lg * 1.5;

  @media screen and (max-width: $screen-sm) {
    height: 100%;
  }
}

.form__body {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.form__title {
  font-size: $font-size-lg;
  font-weight: 900;
  color: $color-dark;
  margin-bottom: $lg * 2;
}

.form__subtitle {
  font-size: $font-size-bg;
  font-weight: 800;
  color: $color-dark;
  margin-bottom: $lg;

  display: flex;
}

.form__warning-text {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $color-warning;
}

.form__phone-field {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-white;
  border: 1.5px solid $color-grey !important;
  border-radius: 16px;
  height: 60px;
  outline: none;
  margin-bottom: $lg !important;
  padding-left: $lg !important;
  padding-right: $lg !important;
  font-weight: bold;
  font-family: 'Inter', 'Montserrat', sans-serif;
  text-decoration: none;
}

.combined-fields__container {
  background-color: $color-white;
  border: 1px solid $color-grey;
  border-radius: 16px;

  margin-bottom: $lg;
  padding-top: $md;
  padding-bottom: $md;
}

.form__error {
  font-size: 12px;
  font-weight: 900;
  color: $color-red;

  margin-left: $lg;
}

.form__actions {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;

  width: 100%;
  padding-right: 0;
}

.field__title {
  color: $color-grey-dark;
  font-size: $font-size-md;
  margin-bottom: $sm;
}

.form-options__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  padding: $lg * 1.5;
  background-color: $color-white;
  border: 1.5px solid $color-grey;
  border-radius: 16px;

  margin-bottom: $lg * 1.5;
}

.form-options__title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: $lg;
}

.form-options__option {
  font-size: 12px;
  font-weight: bold;

  margin-bottom: $lg + $sm;
}

.form__select-users {
  background-color: $color-white;
  border: 1.5px solid $color-grey;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  width: 100%;

  padding: $lg;

  min-width: 450px;
  min-height: 75px;
  max-height: 350px;
  overflow: auto;
}

.checkbox-label {
  font-family: 'Inter', 'Montserrat', sans-serif;
  margin-bottom: $lg;
}

.image-upload {
  width: 100%;
  min-height: 62px;
  background-color: $color-white;

  border-radius: 16px;
  border: 1.5px solid $color-grey;
  padding: $md $md * 1.5 $md $lg;

  > div:first-child {
    min-height: 42px;
  }

  > .img-preview__container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $md;

    img {
      max-height: 100px;
      max-width: 200px;
    }
  }
}

.minimized-input {
  all: unset;
  width: 150px !important;
  border-bottom: 1px solid !important;
}

.quantity-input {
  all: unset;
  width: 35px !important;
  border-bottom: 1px solid !important;
}
