@import '../../../styles/variables';

// Table cells

.table-header-cell {
  display: flex;
  align-items: center;
  min-width: 40px;

  .filter-button {
    //display: none;
  }

  &:hover {
    .filter-button {
      display: block;
    }
  }

  svg {
    font-size: 12px;
  }
}

// Table button

.table-button {
  svg {
    height: 16px;
  }
}

.filter-modal__container {
  padding: 0 $lg * 1.5 $lg * 1.5;

  .filter-modal__options {
    margin-bottom: $lg;
    padding: $lg;
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $lg;
  }

  .form__actions {
    button {
      &:first-child {
        margin-left: $lg;
      }

      &:last-child {
        margin-right: $lg;
      }
    }
  }
}

.case-variant-icon {
  img {
    height: 20px;
    width: 20px;
  }
}
