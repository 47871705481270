@import '../../styles/variables';

.item-image-preview {
  height: $lg * 2.5;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border-radius: $md;

  > img {
    height: $lg * 2.5;
    width: $lg * 2.5;
    border-radius: $md;
  }
}
