@import '../../../styles/variables';

.management-reports-form__container {
  flex: 1;
  //display: flex;
  //justify-content: space-between;

  padding: $lg * 1.5;
  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;

  > div {
    > .management-reports-form__field {
      margin-right: $lg;
      flex: 1;

      > div {
        flex: 1;
        color: $color-grey-dark;
        margin-bottom: $sm;
      }

      > .management-reports-form__select-box {
        padding: $md * 1.5;
        background: $color-white;
        border: 1px solid $color-grey;
        border-radius: $lg;
        height: 58px;
        align-items: center;
        justify-content: space-between;
        display: flex;
        font-weight: bold;
        color: #333333;
        cursor: pointer;
      }
    }

    > .management-reports-form__button {
      align-self: flex-end;
      margin-bottom: $sm;
      margin-left: $lg;
    }
  }
}

.management-reports-chart__container {
  background: $color-white;
  border-radius: 30px;
  flex: 1;
  padding: $lg * 1.5;
  min-height: 300px;


  &:first-child {
    //margin-right: $lg;
    //flex: 0.6
  }

  &:last-child {
    //margin-left: $lg;
    //flex: 0.4;
  }
}

.custom-tooltip {
  padding: $lg;
  background: #ffffff;
  border: 1px solid $color-grey;
  border-radius: $lg;
  opacity: 0.9;

  .tooltip-label {
    font-weight: 700;
  }

  .tooltip-value {
    font-weight: 400;
  }
}