@import '../../styles/variables';

.quarantine-form__container {
  margin-top: $lg * 2.5;
  padding: $lg * 1.5;
  border-radius: $lg;

  background: $color-grey-light;
  border: 1px solid $color-grey;

  .set-number {
    font-size: $font-size-bg;
    font-weight: 800;
    color: $color-dark;
    margin-bottom: $lg;

    display: flex;
  }

  .set-field__container {
    margin-top: $lg;

    .set-field-label {
      margin-bottom: $sm;
      color: $color-grey-dark;
    }

    .set-field {
      height: 60px;
      background: $color-white;
      border-radius: $lg;
      border: 1px solid $color-grey;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 $lg;
      font-weight: bold;

      &.set-field__text-area {
        height: 180px;
        align-items: flex-start;
        padding-top: 16px;
      }
    }

    .MuiFormControl-root {
      margin-bottom: 0;
    }
  }

  .quarantine-form__actions {
    margin-top: $lg;
    display: flex;
    justify-content: space-between;

    > button:first-child {
      margin-right: $md;
    }

    > button:last-child {
      margin-left: $md;
    }
  }

  .set-items__container {
    padding: $lg;
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $lg;
    font-weight: bold;
  }
}

.quarantine-modal__container {
  padding: $lg $lg * 1.5 $lg * 1.5;

  .quarantine-items__container {
    min-height: 100px;
    max-height: 200px;
    overflow: auto;
    padding: $md $lg;
    border-radius: $lg;
    background: $color-white;
    border: 1px solid $color-grey;
  }
}
