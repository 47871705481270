@import '../../../styles/variables';

.notifications-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;

  .notifications-page__body {
    max-height: calc(100% - 60px);
    display: flex;
    flex: 1;

    > div {
      &:not(:first-child) {
        margin-left: $lg
      }

      &:not(:last-child) {
        margin-right: $lg;
      }
    }
  }
}
