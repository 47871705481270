@import '../variables';

.settings-cmp__main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.settings-cmp__body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filters-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-container {
  width: 100%;
  display: flex;
  margin-left: $lg * 1.5;
  //max-width: 500px;
  max-width: 35%;

  flex-direction: column;

  > div:first-child {
    margin-bottom: $lg;
  }

  @media screen and (min-width: 1920px) {
    max-width: 500px !important;
  }
}

.save-changes {
  display: flex;
  justify-content: flex-end;


  width: 100%;
  padding-right: 0;
}

.settings-cmp__title {
  font-size: $font-size-bg;
  font-weight: bold;

  margin-bottom: $lg;
}

.upload-file {
  background: transparent url(../../assets/upload_file.svg) no-repeat center center;
  min-width: 16px;
  min-height: 20px;
}

.settings-cmp__filters--main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: $lg;
}

.settings-cmp__actions {
  display: flex;

  > button {
    &:not(:last-child) {
      margin-right: $md * 1.5;
    }
  }
}

.settings-cmp__filters {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;

  > div:not(:last-child) {
    margin-right: $md * 1.5;
  }
}

.settings-block__middle, .settings-block__right {
  flex: 1;
}

.settings-block__left {
  padding-right: $lg;
  display: flex;
  flex-direction: column;
}
.settings-block__middle {
  padding: 0 $lg;
}
.settings-block__right {
  padding-left: $lg;
}
