@import '../../../styles/variables';

.keyboard_date-picker__container {
  min-height: 60px;
  padding-left: $lg;
  padding-right: $md * 1.5;

  position: relative;
  display: flex;
  align-items: center;

  background: #ffffff;
  border-radius: $lg;
  border: 1px solid $color-grey;

  > div {
    z-index: 10;
    width: 100%;
    height: 58px;
    justify-content: center;
  }

  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }

  &.date-picker__without-styles {
    background: none !important;
    border: none !important;
    width: 100%;
  }
};


.date-picker__container {
  min-height: 60px;
  padding-left: $lg;
  padding-right: $md * 1.5;

  position: relative;
  display: flex;
  align-items: center;

  background: #ffffff;
  border-radius: $lg;
  border: 1px solid $color-grey;

  cursor: pointer;

  > div {
    z-index: 10;
    width: 100%;
    height: 58px;
    justify-content: center;
  }

  > span {
    color: $color-grey-darker;
    position: absolute;
    right: 40px;
    z-index: 9;
  }

  svg {
    position: absolute;
    right: $md * 1.5;
    z-index: 9;

    > span {
      margin-right: $sm;
    }
  }

  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
    cursor: pointer;

    &:before {
      display: none;
    }
    &:after {
      display: none;
    }

    .MuiInputBase-input {
      cursor: pointer;
    }
  }

  &.date-picker__without-styles {
    background: none !important;
    border: none !important;
    width: 100%;
  }

  input:disabled {
    color: #c6c8c9  !important;
  }
};
