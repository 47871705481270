@import '../../../styles/variables';

.reporting-page__container {
  flex: 1;

  .reporting-page__body {
    flex: 1;
  }

  .case-reporting-chart__container {
    display: flex;
    width: 100%;
    height: 372px;

    .case-reporting-chart {
      background: $color-white;
      border-radius: $lg;
      flex: 1;
      padding: $lg * 1.5;
      border: 1px solid $color-grey;
    }
  }
}

.managing-reports-charts__container {
  padding: $lg * 1.5;
  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;
  margin-top: $lg * 1.5;
}