@import '../../styles/variables';

.count-scans__container {
  flex: 1;
  padding: $lg * 1.5;
  margin-top: $lg;

  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;

  .count-scans-actions__container {
    > * {
      margin-left: $md;
    }
  }

  .count-scans__closed-label {

  }
}

.count-notes-list__container, .count-teams-list__container {
  max-height: 156px;
  overflow: auto;
}

.count-image-preview {
  height: $lg * 2.5;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-radius: $md;

  &:not(:last-child) {
    margin-right: $sm;
  }

  > img {
    height: $lg * 2.5;
    width: $lg * 2.5;
    border-radius: $md;
  }
}

.counts-page-filters {
  button {
    margin-right: $lg;
  }
}

.count-scan-modal__container {
  padding: 0 $lg * 1.5 $lg * 1.5;
}
