@import '../../styles/variables';

.app-page {
  display: flex;
  flex-direction: column;

  background-color: #f3f3f4;

  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 90px);
  flex: 1;
}

.app-wrapper {
  flex: 1;
  padding: $lg * 2.5 $lg * 3;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.page-title {
  font-weight: 900;
  font-size: 22px;
  //font-size: 16px;

  margin-bottom: $sm * 2 + $lg;
}

.page-subtitle {
  font-weight: 600;
  font-size: $font-size-md;
  color: $color-grey-dark;
  margin-left: $md;
}
