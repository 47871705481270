@import '../../../styles/variables';

.parent-kit-items-table {
  width: 100%;

  th {
    background-color: transparent;
    color: #A7A9BD;
    text-transform: uppercase;
    font-weight: bold;
    padding: $sm $lg;
    font-size: 12.5px;
  }

  tbody {
    tr {
      font-weight: bold;
      cursor: pointer;
      background: #ffffff;
      border-bottom: 10px solid #f3f3f4;
      border-top: 10px solid #f3f3f4;

      &.bom-items-table__selected-row {
        background: #ffffff;
      }

      td {
        padding: $lg $lg;
        font-size: 12.5px;

        &:first-child {
          border-radius: 24px 0 0 24px;
        }

        &:last-child {
          border-radius: 0 24px 24px 0;
          padding-top: 20px;
        }
      }
    }
  }

  input {
    font-weight: bold;
    border: 1px solid #eee;
    padding: $sm $md;
    max-width: 100px;
    border-radius: $sm;
  }

  .dragging-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $lg;
    border-radius: $lg;
    border: 0;
  }
}