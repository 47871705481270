@import '../../styles/variables';

// Chart

.chart__container {
  background: $color-white;
  border-radius: 30px;
  flex: 1;
  padding: $lg * 1.5;
  margin-right: $lg;
  border: 1px solid $color-grey;
  height: 332px;
}

// Cases number

.cases-number-block {
  margin-left: $lg;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: space-between;

    > .cases-number-item {
      height: 150px;
      width: 150px;
      position: relative;

      background: $color-white;
      border-radius: 30px;
      padding: $lg;
      border: 1px solid $color-grey;

      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .cases-number-item__number {
        align-self: center;
        font-weight: bold;
        //font-size: 36px;
        font-size: 30px;
      }

      .cases-number-item__text {
        //font-weight: bold;
        color: $color-grey-dark;
        font-size: 12px;
      }

      .cases-number-item__status {
        position: absolute;
        top: -20px;
        right: -20px;

        border-radius: $lg;
        width: 70px;
        height: 65px;
        box-shadow: rgba(0, 0, 0, 0.10) 0px 3px 6px, rgba(0, 0, 0, 0.16) 0px 3px 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        .case-status-label {
          margin-top: $sm;
          font-size: $font-size-sm;
          font-weight: 600;
          color: $color-white;
        }
      }
    }
  }
}

// Cases table

.cases-table__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  margin-top: $lg * 2;
  padding: $lg * 1.5;

  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;
}

// Notifications

.home-notifications__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  margin-left: $lg * 2;
  padding: $lg * 1.5;

  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;
}
