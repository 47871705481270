@import '../../../styles/variables';

.admin-page__container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .admin-page__title {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: $sm * 2 + $lg;
  }

  .form__container {
    //max-width: 1200px;
  }
}
