@import 'variables';

// Text color
.primary {
  color: $color-main;
}
.secondary {
  color: $color-grey-dark;
}
.danger {
  color: $color-danger;
}

.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}

.width-50 {
  width: 50%;
}

// Flex-box
.d-flex {
  display: flex;
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.flex-start {
  justify-content: flex-start;
}
.flex-center {
  justify-content: center;
}
.flex-end {
  justify-content: flex-end;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.direction-column {
  flex-direction: column;
}
.direction-row {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

// Spacing
.margin-0 {
  margin: 0 !important;
}

.m-sm {
  margin: $sm !important;
}
.m-b-sm {
  margin-bottom: $sm !important;
}
.m-t-sm {
  margin-top: $sm !important;
}
.m-l-sm {
  margin-left: $sm !important;
}
.m-r-sm {
  margin-right: $sm !important;
}

.m-md {
  margin: $md !important;
}
.m-b-md {
  margin-bottom: $md !important;
}
.m-t-md {
  margin-top: $md !important;
}
.m-l-md {
  margin-left: $md !important;
}
.m-r-md {
  margin-right: $md !important;
}

.m-lg {
  margin: $lg !important;
}
.m-b-lg {
  margin-bottom: $lg !important;
}
.m-t-lg {
  margin-top: $lg !important;
}
.m-l-lg {
  margin-left: $lg !important;
}
.m-r-lg {
  margin-right: $lg !important;
}

.padding-0 {
  padding: 0 !important;
}

.p-sm {
  padding: $sm !important;
}
.p-b-sm {
  padding-bottom: $sm !important;
}
.p-t-sm {
  padding-top: $sm !important;
}
.p-l-sm {
  padding-left: $sm !important;
}
.p-r-sm {
  padding-right: $sm !important;
}

.p-md {
  padding: $md !important;
}
.p-b-md {
  padding-bottom: $md !important;
}
.p-t-md {
  padding-top: $md !important;
}
.p-l-md {
  padding-left: $md !important;
}
.p-r-md {
  padding-right: $md !important;
}

.p-lg {
  padding: $lg !important;
}
.p-b-lg {
  padding-bottom: $lg !important;
}
.p-t-lg {
  padding-top: $lg !important;
}
.p-l-lg {
  padding-left: $lg !important;
}
.p-r-lg {
  padding-right: $lg !important;
}

// Other

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.font-size-xs {
  font-size: 10px;
}
.font-size-sm {
  font-size: 12px;
}
.font-size-bg {
  font-size: 16px;
}
.font-size-lg {
  font-size: 18px;
}
.font-size-xl {
  font-size: 22px;
}

.font-bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}

.underline, .border-bottom {
  border-bottom: 1px solid $color-silver;
}

.border-top {
  border-top: 1px solid $color-silver;
}

.default-styled-link {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

