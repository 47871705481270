@import '../../styles/variables';

.main-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 90px;

  background-color: #fcfcfc;

  padding-right: $lg * 3;

  .header-icon {
    margin-left: $lg * 2;
    font-size: 22px;
    cursor: pointer;
  }
}

.user-name {
  font-family: 'Inter', 'Montserrat', sans-serif;
  font-size: $font-size-bg;
  font-weight: bold;
  color: #272D26;
}

.role {
  font-size: 11px;
  //font-weight: bold;
  text-transform: uppercase;
}

.user-image {
  background: transparent url(../../assets/user-image.svg) no-repeat center center;
  width: 40px;
  height: 40px;

  margin-right: $lg;
}

.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-left: $lg * 3;

  .user-avatar {
    height: 40px;
    width: 40px;
    border-radius: $md;
    margin-right: $md;

    > img {
      height: 40px;
      width: 40px;
      border-radius: $md;
    }
  }
}

.down-arrow {
  background: transparent url(../../assets/down-arrow.svg) no-repeat center center;
  width: 17px;
  height: 13.98px;

  cursor: pointer;
}

.messages-icn {
  background: transparent url(../../assets/messages.svg) no-repeat center center;
  width: 22.33px;
  height: 20px;

  margin-left: $lg * 3;

  cursor: pointer;
}

.logo-user {
  display: flex;
  flex-direction: row;
  height: 100%;
}
