@import '../../../styles/variables';

.profile-page__container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .profile-page__title {
    font-weight: 600;
    font-size: 22px;
    //font-size: 16px;
    margin-bottom: $sm * 2 + $lg;
  }

  .form__container {
    //max-width: 1200px;
  }
}
