@import '../../styles/variables';

.filters-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.page__filters--main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: $lg;
}

.page__actions {
  display: flex;
  width: 100%;

  > button {
    margin-right: $md * 1.5;
  }
}

.page__filters {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;

  > div {
    margin-right: $md * 1.5;
  }
}

.status-filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  > button {
    margin-left: $lg;
  }
}

.filter-button__label {
  color: #ffffff;
  font-size: $font-size-sm;
  text-transform: capitalize !important;
}

.case-info__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  margin-right: $md;

  .case-actions {
    margin-bottom: $lg;
    display: flex;
    justify-content: space-between;

    > div:first-child {
      > button:first-child {
        margin-right: $lg;
      }
    }
  }
}

.case-info {
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: $lg * 1.5;
  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;

  .case-info__main {
    display: flex;
    justify-content: space-between;
  }

  .divider {
    margin-top: $lg * 2;
    margin-bottom: $lg * 2;
    border-bottom: 1px solid $color-grey;
  }

  .case-status {
    border-radius: $lg;
    width: 80px;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    .case-status-label {
      font-size: $font-size-sm;
      font-weight: 600;
      color: $color-white;
    }
  }

  .case-buttons {
    width: 100%;
    margin-top: $lg * 2;
    display: flex;

    > .case-button {
      flex: 1;
      padding: $lg;

      background: $color-white;
      border: 1px solid $color-grey;
      border-radius: $lg;

      cursor: pointer;

      &:first-child {
        margin-right: $md;
      }

      &:last-child {
        margin-left: $md;
      }
    }
  }

  .case-kit-variant {
    padding: $sm $lg;
    border: 2px solid #000000;
    border-radius: $md * 1.5;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    width: fit-content;
  }
}

.case-notes__container, .case-usage__container, .case-documents__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  margin-left: $md;
  margin-right: $md;
  padding: $lg * 1.5;

  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;

  .case-notes-list__container {
    flex: 1;
    padding: $lg;
    //min-height: 150px;

    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $lg;

    .case-note {
      margin-bottom: $lg;

      &:not(:last-child) {
        padding-bottom: $md * 1.5;
        border-bottom: 1px solid $color-grey;
      }

      .case-note-actions {
        display: flex;
        align-items: center;
        margin-left: $md;

        svg {
          font-size: 16px;
        }
      }
    }
  }

  .case-documents-list__container {
    flex: 1;
    padding: $lg;
    //min-height: 150px;

    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $lg;

    .case-document {
      margin-bottom: $lg;
      display: flex;
      justify-content: space-between;
    }
  }
}

.case-booking-note__container {
  padding: $lg;
  border-radius: 16px;
  border: 2px solid $color-warning;
  background: #f4eeea;
  margin-top: $lg;
  display: flex;
}

.case-cancelled__container {
  padding: $lg;
  border-radius: 16px;
  border: 2px solid #F53434;
  background: #f5eaea;
  margin-top: $lg;
  display: flex;
}

.case-usage-tabs__container {
  flex: 1;
}

.case-implants__container {
  .case-implant-item {
    margin-top: $lg;
    padding: $lg;

    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $lg;

    display: flex;
    justify-content: space-between;

    .case-implant-value {
      display: flex;

      > div:first-child {
        color: $color-grey-dark;
        width: 50px;
        margin-right: $md;
      }
    }
  }
}

.case-guidance-modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: $lg $lg $lg $lg * 2;

  > div {
    width: 100%;
  }
}

.case-preferences-modal__container {
  padding: $lg $lg * 1.5 $lg * 1.5;

  .case-preferences-list__container {
    flex: 1;
    padding: $lg;
    min-height: 150px;

    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $lg;

    .case-preference:not(:last-child) {
      margin-bottom: $lg;
    }
  }
}

.case-kits__container, .count-scans__container {
  flex: 1;
  padding: $lg * 1.5;
  margin-top: $lg;

  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;

  .case-sets__container-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 9px 9px 12px;
    background-color: #ffffff;
    border: 1px solid $color-grey;
    border-radius: 12px;
  }

  .case-sets-actions__container {
    > * {
      margin-left: $md;
    }
  }
};

.case-sets-modal__container {
  padding: $md $lg * 1.5 $lg * 1.5;
}

.case-flow-file__item {
  display: flex;
  padding: $md $md * 1.5;
  margin-bottom: $md;
  border-radius: $md;
  border: 1px solid $color-grey-dark;
  cursor: pointer;

  svg {
    margin-right: $md;
  }
}

.sets-chart__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  padding: $lg * 1.5 $lg * 1.5 $lg * 1.5 $md;

  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;

  //max-height: 277px;

  //> div {
  //  flex: 1;
  //}

  > div:last-child {
    min-width: 180px;
  }

  .sets-chart__legend {
    > div {
      display: flex;
      align-items: center;

      > div:first-child {
        width: $lg;
        height: $lg;
        margin-right: $md;
        border-radius: $sm;
      }

      > div:last-child {
        //font-weight: bold;
        color: $color-grey-dark;
      }
    }
  }
}

.case-modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: $md $lg * 1.5 $lg * 1.5;

  > div {
    width: 100%;
  }

  .case-form-preview__container {
    .case-form-preview__item {
      background: $color-white;
      border: 1px solid $color-grey;
      border-radius: $lg;

      display: flex;
      justify-content: space-between;
      padding: $lg;

      &:not(:last-child) {
        margin-bottom: $lg;
      }

      > svg {
        margin-left: $md;
      }
    }
  }
}

.documents-upload-modal__container {
  padding: $lg $lg * 1.5 $lg * 1.5;
}

.case-usage-modal__container {
  padding: 0 $lg * 1.5 $lg * 1.5;
}

.close-order-modal__container {
  padding-left: $lg * 1.5;
  padding-right: $lg * 1.5;
}

.increment-button {
  height: 22px;
  width: 22px;
  border: 2px solid $color-grey-darker;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;
    fill: $color-grey-darker;
  }
}

// Usage info

.case-usage-info__field, .case-usage-info__field--combined {
  display: flex;
  justify-content: space-between;
  padding: $lg;
  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;
}

.case-usage-info__field--combined {
  flex-direction: column;

  > div:first-child {
    margin-bottom: $lg;
  }
}

.cases-table {
  tbody > tr {
    > th:nth-child(2), > th:nth-child(3) {
      padding: 0;
    }
  }
}

.case-history__container {
  width: 100%;
  flex: 1;

  margin-top: $lg;
  padding: $lg * 1.5;

  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;

  .case-history__empty {
    text-align: center;
    padding: 16px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: $color-grey-dark;
  }
}

.ship-notes-modal__container {
  padding: $lg $lg * 1.5 $lg * 1.5;

  .ship-note {
    padding: $lg;
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $lg;
  }
}

.case-flow__active-step {
  height: $lg * 1.5;
  width: $lg * 1.5;
  margin-left: $md;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  border-radius: 50%;
  font-weight: bold;
}

.dispatch-modal-checkboxes__container {
  padding: $md $lg;
  border-radius: $lg;
  background: $color-white;
  border: 1px solid $color-grey;
  display: flex;
  flex-direction: column;
}

.sets-table {
  thead {
    th:nth-child(2) {
      width: 40px !important;
      max-width: 40px !important;
      padding: 0;
    }
  }
  tbody {
    .set-indicator {
      background: #949494;
      color: #ffffff;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      min-height: 18px;
      min-width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }

    td:nth-child(2) {
      width: 40px !important;
      max-width: 40px !important;
      padding: 0;
    }
  }
}

.case-form-form__container {
  width: 60%;

  margin-top: $lg;
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-left: $md;
  margin-right: $md;
  padding: $lg * 1.5;

  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;
}

.case-form-form__checkbox-container {
  margin-bottom: $lg;
  padding: $lg;
  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;
}

// Calendar View

.calendar-view-event__request {
  //text-wrap: inherit;
  cursor: pointer;
  background: $color-request;
  border-color: $color-request;
}
.calendar-view-event__booked {
  //text-wrap: inherit;
  cursor: pointer;
  background: $color-booked;
  border-color: $color-booked;
}
.calendar-view-event__overdue {
  //text-wrap: inherit;
  cursor: pointer;
  background: $color-overdue;
  border-color: $color-overdue;
}
.calendar-view-event__completed {
  //text-wrap: inherit;
  cursor: pointer;
  background: $color-completed;
  border-color: $color-completed;
}

.fc {
  font-family: 'Inter', 'Montserrat', sans-serif;

  .fc-toolbar-title {
    font-size: 20px;
    font-weight: 900;
  }

  .fc-button {
    text-transform: capitalize;
    color: #fff;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Inter', 'Montserrat', sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 8px;
    box-shadow: none;
    background: #00AEEF;
    border-color: #fff;

    &:focus, &:hover, &:active, &:focus-visible, &:visited {
      box-shadow: none !important;
      background: #00AEEF !important;
      border-color: #ffffff !important;
    }

    &.fc-button-active {
      background: #2199ca !important;
      border-color: #ffffff !important;
    }

    &.fc-today-button:disabled {
      border-color: $color-grey-dark !important;
      background-color: $color-grey-dark !important;
    }
  }

  td {
    background-color: #ffffff;
  }

  .fc-event {
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.case-kit-bom__container {
  .image-container {
    display: flex;
    min-height: 150px;
    max-height: 230px;
    align-items: center;
    justify-content: center;
    background: $color-white;
    border-radius: 30px;
    border: 1px solid $color-grey;
    position: relative;
    margin-top: $md;
    margin-bottom: $md;

    > img {
      padding: $lg * 1.5 $lg;
      max-height: 230px;
      //max-height: 100%;
      width: auto;
      max-width: calc(100% - 32px);
    }

    .full-screen-icon {
      bottom: $lg;
      right: $lg;
      position: absolute;
      cursor: pointer;
    }
  }

  .bom-items-table {
    width: 100%;

    th {
      background-color: transparent;
      color: #A7A9BD;
      text-transform: uppercase;
      font-weight: bold;
      padding: $sm $lg;
      font-size: 12.5px;
    }

    tbody {
      tr {
        font-weight: bold;
        cursor: pointer;
        background: #ffffff;
        border-bottom: 10px solid #fafafa;
        border-top: 10px solid #fafafa;

        &.bom-items-table__selected-row {
          background: #ffffff;
        }

        td {
          padding: $md * 2.5 $lg;
          font-size: 12.5px;

          &:first-child {
            border-radius: 24px 0 0 24px;
          }

          &:last-child {
            border-radius: 0 24px 24px 0;
            padding-top: 20px;
          }
        }
      }
    }
  }
}

.sets-table__allocation-status-cell {
  padding: 6px $lg * 1.5;
  font-size: 11px;
  height: 30px;
  border: 1px solid $color-grey;
  background-color: #fafafa;
  border-radius: $md;
  text-align: center;

  &.SHIPPED, &.COMPLETE, &.USAGE, &.CONFIRMED {
    border-color: $color-booked;
    background-color: #e6f0d0;
  }

  &.RETURNED, &.AVAILABLE, &.INSPECT {
    border-color: $color-completed;
    background-color: #e5f7fe;
  }

  &.ASSIGNED, &.TRANSFERRED, &.INCOMPLETE {
    border-color: $color-request;
    background-color: #fdecd5;
  }
}
