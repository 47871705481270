@import '../../../styles/variables';

.tree-view__container {
  height: 100%;
  padding: $md;

  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: 16px;
}
