@import '../../../styles/variables';

.split-view-modal__container {
  display: flex;
  flex-direction: column;
  padding: $lg * 1.5;
  background: $color-white;

  .split-view-modal__content {
    display: flex;
    flex: 1;

    > div:first-child {
      position: relative;
      flex: 0.7;
      margin-right: $lg;
      max-width: 75%;
      max-height: calc(100vh - 200px);
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-grey-light;
      padding: $lg;
      border: 1px solid $color-grey;
      border-radius: $lg;

      .react-transform-component {
        max-height: 100%;
        max-width: 100%;

        img {
          max-height: calc(100vh - 200px);
        }
      }

      .react-transform-wrapper {
        max-height: 100%;
        max-width: 100%;
      }

      img {
        max-height: 100%;
        max-width: 100%;
      }

      .split-view-modal__tools {
        position: absolute;
        right: $md;
        bottom: -$lg * 2.5;
        z-index: 100;

        > button {
          margin-left: $md;
        }
      }
    }

    .split-view-modal__inventory {
      flex: 0.3;
      margin-left: $lg;
      background: $color-grey-light;
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: calc(100vh - 200px);
      padding: $lg;
      border-radius: $lg;
      border: 1px solid $color-grey;

      > div {
        background: $color-white;
        border: 1px solid $color-grey;
        padding: $lg;
        border-radius: $lg;
      }

      > div:not(:last-child) {
        margin-bottom: $lg;
      }
    }
  }

  .split-view-modal__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: $lg;

    > div:first-child {
      flex: 0;
      margin-right: $lg;

    }

    > div:last-child {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > button:first-child {
        margin-left: -$sm;
      }
    }
  }
}

.add-usage-modal__container {
  display: flex;
  flex-direction: column;
  padding: $lg * 1.5;

  .add-usage-modal__content {
    flex: 1;
    display: flex;

    .add-usage-modal__list {
      border: 1px solid $color-grey;
      background: $color-white;
      padding: $md;
      margin-right: $md;
      flex: 0.25;
      height: 100%;
      border-radius: $lg;
      min-height: calc(100vh - 300px);
      overflow: auto;

      > div {
        padding: $md;
        border-radius: $md * 1.5;
        cursor: pointer;

        &.selected {
          background: #eeeeee;
        }
      }
    }

    .add-usage-modal__main {
      margin-left: $md;
      flex: 0.75;
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 300px);

      .add-usage-modal__search {
        display: flex;

        > div:first-child {
          margin-right: $sm;
        }

        > div:last-child {
          margin-left: $sm;
        }
      }

      .add-usage-modal__items-list {
        border-radius: $lg;
        border: 1px solid $color-grey;
        background: $color-white;
        padding: $lg;
        flex: 1;
        overflow: auto;
      }

      .add-usage-modal__section {
        &:not(:first-child) {
          margin-top: $sm;
        }
        &:not(:last-child) {
          margin-bottom: $sm;
          border-bottom: 1px solid $color-grey;
        }
      }
    }
  }

  .add-usage-modal__actions {
    margin-top: $lg;
    display: flex;
    justify-content: space-between;

    > div {
      //> button:first-child {
      //  margin-right: $lg;
      //}
    }
  }
}
