@import '../../../styles/variables';

//.customers-page__body {
//  width: 100%;
//  display: flex;
//  flex-direction: row;
//  justify-content: space-between;
//  flex: 1;
//
//  > div {
//    flex: 1;
//
//    &:not(:first-child) {
//      margin-left: $md;
//    }
//
//    &:not(:last-child) {
//      margin-right: $md;
//    }
//  }
//}

.customers-page__body {
  background-color: $color-grey-light;
  padding-left: 2 * ($lg + $sm);
  padding-top: 2 * ($lg + $sm);

  display: inline-flex;
  flex-wrap: wrap;

  border: 1.5px solid $color-grey;
  border-radius: 30px;

  .customers-page-option {
    --colorHover: $color-main;

    cursor: pointer;
    height: 180px;
    padding: 2 * $lg $sm * 2 + $lg $sm * 2 + $lg 2 * $lg;

    display: flex;
    flex-direction: column;

    background-color: $color-white;
    border: 2px solid $color-grey;
    border-radius: 16px;
    margin-bottom: 2 * ($lg + $sm);
    margin-right: 2 * ($lg + $sm);

    &:hover {
      border-color: var(--colorHover);
    }

    .option-title {
      font-weight: 600;
      font-size: 18px;
      color: $color-dark;
    }

    .option-sub-t {
      //font-weight: bold;
      color: $color-grey-dark;

      margin-top: $md;
      margin-bottom: 28px;
    }

    .option-icn {
      width: 36px;
      height: 36px;

      color: #1D1D1D;
    }

    .option-icn-cnt {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      width: 100%;
    }
  }
}

.customers-page {
  display: flex;
  flex: 1;

  > div {
    flex: 0.3;
  }
}

.customers-inventory-page {
  .input-container {
    margin-top: $md * 1.5;
    width: 400px;
  }
}

.count-page {
  .count-page__body {
    display: flex;

    > div:first-child {
      flex: 0.4;
    }
    > div:nth-child(2) {
      flex: 0.3;
    }
    > div:last-child {
      flex: 0.3;
    }
  }
}
