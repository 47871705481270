@import '../../../styles/variables';

.case-checklists-info__container {
  padding: $lg * 1.5;
  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;
  height: 136px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.case-checklists__container {
  margin-top: $lg * 2;
  padding: $lg;
  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;
  flex: 1;
  width: 100%;

  .case-checklist-item {
    padding: $md $lg;
    cursor: pointer;
    //margin-left: 16px;
    //margin-right: 16px;
    border-radius: 0 12px 12px 0;
    border-left: 3px solid transparent;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &.selected {
      background-color: $color-grey;
      border-left: 3px solid $color-grey;
    }
  }
}

.checklist-section-item {
  width: 100%;
  flex: 1;

  padding: $lg * 1.5;

  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;

  &:not(:first-child) {
    margin-top: $lg;
  }

  .section-image__container {
    min-height: 200px;
    max-height: 350px;
    background: $color-white;
    margin-top: $lg;
    padding: $lg;
    border-radius: $lg;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      max-width: 100%;
      max-height: 320px;
    }

    .full-screen-icon {
      bottom: $lg;
      right: $lg;
      position: absolute;
      cursor: pointer;
    }
  }
}

.section-items-table {
  width: 100%;

  th {
    background-color: transparent;
    color: #A7A9BD;
    text-transform: uppercase;
    font-weight: bold;
    padding: $sm $lg;
    font-size: 12.5px;
  }

  tbody {
    tr {
      font-weight: bold;
      cursor: pointer;
      background: #ffffff;
      border-bottom: 10px solid #fafafa;
      border-top: 10px solid #fafafa;

      &.bom-items-table__selected-row {
        background: #ffffff;
      }

      td {
        padding: $md * 2.5 $lg;
        font-size: 12.5px;

        &:first-child {
          border-radius: 24px 0 0 24px;
        }

        &:last-child {
          border-radius: 0 24px 24px 0;
          padding-top: 20px;
        }
      }
    }
  }

  input {
    font-weight: bold;
    border: 1px solid #eee;
    padding: $sm $md;
    max-width: 100px;
    border-radius: $sm;
  }

  .dragging-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $lg;
    border-radius: $lg;
    border: 0;
  }
}

.submit-modal__container {
  padding: 0 $lg * 1.5;

  .checklist-data__container {
    margin-top: $lg;
    padding: $lg;
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $lg;
  }

  .checklist-updated-table {
    margin-top: $md;
    padding-top: $md;
    border-top: 1px solid $color-grey;
    display: flex;
    justify-content: space-between;
  }
}
