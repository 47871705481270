@import '../../../styles/variables';

.autocomplete__input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-white;
  border: 1px solid $color-grey !important;
  border-radius: 16px;
  height: 60px;
  outline: none;
  font-weight: bold !important;
  text-decoration: none;
  font-size: 0.8rem !important;

  input {
    padding-left: $lg !important;
  }

  .MuiAutocomplete-endAdornment {
    right: 6px;
  }
}