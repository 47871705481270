@import '../../../styles/variables';

.home-page__container {
  flex: 1;
  //max-height: 100%;

  .home-page__body {
    display: flex;
    justify-content: space-between;
    //max-height: calc(100% - 42px);

    > div:first-child {
      flex: 0.7;
    }
    > div:last-child {
      flex: 0.3;
    }

    .home-page__row-container {
      display: flex;
    }
  }
}
