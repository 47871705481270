@import '../../../styles/variables';

.set-turns-form__container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: $lg * 1.5;
  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;

  > .set-turns-form__field {
    margin-right: $lg;
    flex: 1;

    > div {
      color: $color-grey-dark;
      margin-bottom: $sm;
    }

    > .set-turns-form__select-box {
      padding: $md * 1.5;
      background: $color-white;
      border: 1px solid $color-grey;
      border-radius: $lg;
      height: 58px;
      align-items: center;
      justify-content: space-between;
      display: flex;
      font-weight: bold;
      color: #333333;
      cursor: pointer;
    }
  }

  > .set-turns-form__button {
    align-self: flex-end;
    margin-bottom: $sm;
  }
}
