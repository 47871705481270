@import '../../styles/variables';

.kits-filters-modal__container {
  padding: $lg $lg * 1.5 $lg * 1.5;
}

.kit-filters-list__container {
  display: flex;
  align-items: center;
  //margin: $lg 0;

  > .MuiChip-root {
    margin-left: $md;
    font-size: $font-size-md;
  }
}

.section-list__container {
  height: 100%;
  padding: $md;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: $lg;

  .section-list__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-left: $lg * 1.5;
    padding: $md;
    border-radius: $md;
    cursor: pointer;

    > span {
      margin-left: $md;
      font-weight: bold;
    }

    &:hover {
      background: $color-grey-light;
    }
  }
}

.bom-items-table {
  width: 100%;

  th {
    background-color: transparent;
    color: #A7A9BD;
    text-transform: uppercase;
    font-weight: bold;
    padding: $sm $lg;
    font-size: 12.5px;
  }

  tbody {
    tr {
      font-weight: bold;
      cursor: pointer;
      background: #fafafa;
      border-bottom: 10px solid #f3f3f4;
      border-top: 10px solid #f3f3f4;

      &.bom-items-table__selected-row {
        background: #ffffff;
      }

      td {
        padding: $md * 2.5 $lg;
        font-size: 12.5px;

        &:first-child {
          border-radius: 24px 0 0 24px;
        }

        &:last-child {
          border-radius: 0 24px 24px 0;
          padding-top: 20px;
        }
      }
    }
  }

  input {
    font-weight: bold;
    border: 1px solid #eee;
    padding: $sm $md;
    max-width: 100px;
    border-radius: $sm;
  }

  .dragging-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $lg;
    border-radius: $lg;
    border: 0;
  }
}

.section-items-modal__container {
  .section-items-modal__filters {
    display: flex;
    align-items: center;
    margin-bottom: $lg;

    > div {
      flex: 1;

      &:first-child {
        > div {
          margin-bottom: 0;
        }
      }
    }
  }

  .section-items-modal__table {
    thead {
      border-bottom: 1px solid $color-grey;

      th {
        padding: $sm;
        cursor: pointer;

        > span:last-child {
          vertical-align: middle;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: $sm;
        }

        td:last-child {
          min-width: 90px;
        }
      }
    }
  }
}

.bom-page__container {
  .disabled-field {
    .MuiInputBase-input.Mui-disabled {
      color: $color-grey-dark !important;
    }
  }
}
