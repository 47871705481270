@import '../../../styles/overrides';

.analytics-page__body {
  background-color: $color-grey-light;
  padding: $lg * 2;

  flex: 1;
  display: flex;
  flex-direction: column;

  border: 1.5px solid $color-grey;
  border-radius: 30px;

  > div {
    display: flex;
    flex: 1;
  }

  .analytics-chart__container {
    background: $color-white;
    border-radius: 30px;
    flex: 1;
    padding: $lg * 1.5;

    &:first-child {
      margin-right: $lg;
      //flex: 0.6
    }

    &:last-child {
      margin-left: $lg;
      //flex: 0.4;
    }
  }
}
