@import '../../styles/variables';;

.block__container {
  padding: $lg * 1.5;

  display: flex;
  flex-direction: column;

  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;

  &:not(:last-child) {
    margin-right: $lg * 2;
  }
}

.list-item {
  padding: $lg;
  min-height: 84px;

  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: $lg;
  }
}

.block-header {
  display: flex;
  justify-content: space-between;
  width: 100%;

  > div:first-child, > div:last-child {
    width: 21px;
  }
}

.block-body {
  padding: $lg;
  margin-top: $lg;
  flex: 1;
  width: 100%;

  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;

  .block-divider {
    margin: $lg * 1.5 0;
    border-bottom: 1px solid $color-grey;
  }
}
