@import '../../styles/variables';

.notifications__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  padding: $lg * 1.5;
  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: $lg;
  overflow: auto;
}

.notification-item {
  width: 100%;
  padding: $lg;
  cursor: pointer;

  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;

  display: flex;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: $lg;
  }

  .notification-item__icon {
    margin-right: $md;
  }
}
