@import '../../styles/variables';

.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: $md * 3 $lg * 2;
  background-color: $color-white;
}

.logo {
  background: transparent url(../../assets/logo.svg) no-repeat center center;
  min-width: 34.5px;
  min-height: 40px;
  margin-right: $lg
}

.header-text {
  font-family: 'Inter', 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 28px;
  color: $color-dark;
}
