@import '../../../styles/variables';

.cases-page__body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.calendar__container {
  background: #ffffff;
  padding: $lg;
  border-radius: 16px;

  .clear-button__container {
    display: flex;
    justify-content: flex-end;
  }
}

.cases-form__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: $lg * 1.5;
  max-width: 342px !important;

  > div:first-child {
    margin-bottom: $lg;
  }
}

.editing-by-badge {
  display: flex;
  padding: $sm $md;
  border-radius: $md;
  background: $color-white;
  border: 2px solid #F58634;
  color: #F58634;
  font-weight: bold;

  > svg {
    margin-right: $sm;
  }
}

.case-page__body {
  display: flex;

  > div:first-child {
    flex: 0.4;
  }
  > div:nth-child(2) {
    flex: 0.3;
  }
  > div:last-child {
    flex: 0.3;
  }
}

.case-checklists-page__container {
  .case-checklists-page__body {
    display: flex;
    justify-content: space-between;

    > div:first-child {
      flex: 0.25;
      margin-right: $lg;

      button {
        width: 100%;
      }

      button:first-child {
        margin-bottom: $lg;
      }
    }

    > div:last-child {
      flex: 0.75;
      margin-left: $lg;
    }
  }
}

.case-page-title {
  font-weight: 900;
  font-size: 22px;
  //font-size: 16px;

  margin-bottom: $sm * 2 + $lg;

  padding: $md;
  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;
}
