@import '../../../styles/variables';

.color-picker {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;

  background-color: $color-white;
  border: 1px solid #EEEEEE;
  border-radius: 16px;

  padding-left: $lg;
  padding-right: 7px;

  svg {
    color: #c5c8c9;
  }
}

.color-picker__color-box {
  --color-background: $color-main;
  height: 30px;
  width: 30px;

  border-radius: 10px;

  margin-right: $md + $sm;
  background-color: var(--color-background);
}

.color-picker__form {
  z-index: 100;
  position: absolute;
  left: 0;
}

.flex-centered-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.color-text {
  font-family: 'Inter', 'Montserrat', sans-serif;
  font-weight: bold;
}

.change-text {
  font-family: 'Inter', 'Montserrat', sans-serif;

  color: $color-grey-darker;
}
