@import 'variables';

// Hide bottom border for autocomplete

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiAutocomplete-inputRoot.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
}
.MuiMenu-paper.MuiPaper-rounded {
  border-radius: 16px !important;
}

// Select component

.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
  transition: none;
  background: none;

  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
}
.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
  height: 100%;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  padding-right: 0;
}
ul.MuiList-root.MuiMenu-list.MuiList-padding {
  max-height: 376px;

  li {
    width: 100%;
  }
}
.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-filled.MuiInputBase-input.MuiFilledInput-input {
  padding: 0;
}

// Country select

.MuiInputBase-input.MuiInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd {
  font-weight: bold;

  input {
    &::placeholder {
      font-weight: normal !important;
      color: #a7a7a7 !important;
    }
  }
}

// Modal component

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiPaper-elevation24.MuiPaper-rounded {
  background: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: 30px;

  .MuiDialogContent-root:first-child {
    padding-top: 0;
  }
}

// Date picker modal

.MuiPaper-root.MuiDialog-paper.MuiPickersModal-dialogRoot.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  background: #ffffff !important;
  border: none !important;
}

.MuiInputBase-input {
  font-family: 'Inter', 'Montserrat', sans-serif !important;
  color: #0B0B0B !important;
  font-weight: bold !important;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.MuiInputBase-input::placeholder {
  font-weight: normal !important;

  input::placeholder {
    font-weight: normal !important;
  }
}

.MuiDialogContent-root {
  .form__container {
    min-width: 450px;
  }
}

// Table

.MuiTable-root {
  .MuiFormControlLabel-root {
    margin-right: 0;
  }
}

// Date Picker

.MuiPickersStaticWrapper-staticWrapperRoot {
  .MuiPickersBasePicker-container {
    align-items: center;
  }

  .MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar.MuiToolbar-gutters {
    display: none;
  }

  .MuiPickersDay-daySelected {
    background-color: #0B0B0B !important;

    p.MuiTypography-root {
      font-size: 1.1rem;
      font-weight: 600;
      color: #ffffff !important;
    }
  }

  .MuiDatepickerDay-container {

    &.overdue {
      p.MuiTypography-root {
        color: #F53434;
        font-weight: 600;
      }
    }
    &.request {
      p.MuiTypography-root {
        color: #F58634;
        font-weight: 600;
      }
    }
    &.booked {
      p.MuiTypography-root {
        color: #81B214;
        font-weight: 600;
      }
    }
    &.completed {
      p.MuiTypography-root {
        color: #00AEEF;
        font-weight: 600;
      }
    }
  }
}

.MuiInputBase-root.MuiInputBase-multiline {
  height: auto;
  min-height: 60px;
}

// Hide arrows from number input

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

// Change placeholder color

.MuiInputBase-root.MuiInputBase-adornedStart {
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-grey-dark;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color-grey-dark;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $color-grey-dark;
  }
}

.action-button--disabled {
  background-color: #dddddd;

  svg, path {
    stroke: $color-grey-darker;
  }
}

.action-icon-button {
  svg {
    //font-size: 16px;
  }

  //&.--disabled {
  //  svg, path {
  //    stroke: $color-grey-darker;
  //  }
  //}
}

// Alert snackbar

.MuiSnackbarContent-root {
  flex-wrap: unset !important;
}

// Virtualized table

.ReactVirtualized__Table__headerRow {
  height: 36px;
  border-bottom: 1px solid #EEEEEE;
}