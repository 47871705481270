@import '../../styles/variables';

.side-menu {
  --menu-width: 250px;
  min-width: var(--menu-width);
  height: 100%;
  background-color: $color-white;

  border-radius: 0 0 30px 0;
  overflow: auto;
}

.logo-case {
  width: 35px;
  height: 40px;

  display: flex;
  align-items: center;

  > img {
    width: 40px;
  }
}

.title-container {
  --compressed-width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: var(--compressed-width);
  height: 100%;

  padding-left: 2 * $lg;

  border-radius: 0 30px 0 0;
  background-color: $color-white;
}

.tenant-name-container {
  display: flex;
  align-items: center;
  padding-top: $md;

  .tenant-name {
    font-family: 'Inter', 'Montserrat', sans-serif;
    font-weight: bold;
    //font-size: 22px;
    font-size: 16px;
    color: $color-dark;
    margin-left: 1.5 * $md;
    max-width: 150px;

    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.navigation-arrow {
  --position-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: $color-white;
  background-color: $color-main;

  border-radius: 8px;

  cursor: pointer;

  position: relative;
  left: var(--position-left);
}

.menu-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-left: 2 * $lg + $sm * 1.5;
  margin-top: 3 * $lg + $sm;
  height: calc(100% - 52px)
}

.item {
  --colorHover: $color-main;

  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Inter', 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: $font-size-bg;
  color: $color-dark;
  height: 24px;

  margin-bottom: $lg * 2;

  cursor: pointer;
}

.item:hover {
  color: var(--colorHover) !important;

  svg {
    path, circle {
      stroke: var(--colorHover) !important;
    }
  }
}

.notifications {
  --color-background: $color-main;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5 * $sm;
  background-color: var(--color-background);

  height: 16px;
  border-radius: 3px;
  padding: 2px 5px;

  color: $color-white;
  font-size: 10px;
  font-weight: bold;
}
