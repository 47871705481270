@import '../../../styles/variables';

.sets-page__container {
  .sets-page__body {
    display: flex;

    > div:first-child {
      flex: 0.65;
      margin-right: $lg;
    }

    > div:last-child {
      flex: 0.35;
      margin-left: $lg;
    }

    .sets-page-filters {
      display: flex;

      button {
        margin-right: $lg;
      }
    }
  }
}
