@import '../../styles/variables';

.flows-list__container {
  height: 100%;
  padding: $md;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: $lg;

  .flow-list__item {
    display: flex;
    padding: $md;
    border-radius: $md;
    cursor: pointer;

    > span {
      margin-left: $md;
      font-weight: bold;
    }

    &:hover {
      background: $color-grey-light;
    }

    &:not(:first-child) {
      margin-left: $lg * 1.5;
    }
  }
}

.flow-step-index {
  height: $lg * 2;
  width: $lg * 2;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #333333;
  color: #ffffff;
  border-radius: 50%;
  padding: $sm;
  font-size: 18px;
  font-weight: bold;
}
