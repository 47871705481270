@import '../../../styles/variables';

.modal-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 62px;
  background-color: $color-white;

  border-radius: 16px;
  border: 1.5px solid $color-grey;
  padding: $md $md * 1.5 $md $lg;
}

.modal-select__count {
  font-weight: bold;
  word-break: break-word;
}

.modal-select__placeholder {
  padding-left: $sm;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-family: 'Inter', 'Montserrat', sans-serif;

  > span {
    margin-right: 6px;
    color: $color-grey-darker;
    font-weight: normal;
  }
}

.md-user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-family: 'Inter', 'Montserrat', sans-serif;
  font-weight: bold;

  .md-user-info__delete {
    cursor: pointer;
    color: $color-danger;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
