/* Custom styles for ag-Grid */
.ag-theme-custom {
  .ag-root-wrapper {
    border-radius: 16px;
  }
  /* Override the background color of the header */
  .ag-header {
    background-color: #f2f2f2;
  }

  /* Override the font color of the header text */
  .ag-header-cell-text {
    color: #333333;
  }

  /* Override the background color of the selected rows */
  .ag-row-selected {
    background-color: #e6e6e6;
  }

  /* Override the font color of the cell text */
  .ag-cell-value {
    color: #555555;
  }
}