@import '../../styles/variables';

.login-page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;

  background-color: $color-white;
}

.logo {
  background: transparent url(../../assets/logo.svg) no-repeat center center;
  min-width: 40px;
  min-height: 46.38px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $lg * 1.5;

  width: 500px;

  background-color: $color-grey-light;
  border: 1px solid $color-grey;
  border-radius: 30px;
}

.login-text {
  //font-size: 28px;
  font-size: 18px;
  font-weight: 900;

  margin-top: 3 * $lg;
}

.login-subtext {
  font-size: 12px;
  font-weight: bold;

  margin-top: $md;
  margin-bottom: 3 * $lg + $md;
}

.right-arrow {
  background: transparent url(../../assets/go.svg) no-repeat center center;
  min-width: 20px;
  min-height: 7px;
  margin-left: $lg;
}

.signup-btn {
  background-color: $color-dark;
}

.btn-group {
  width: 100%;
  margin-top: $md;
  margin-bottom: $lg * 2;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flx-cntr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.text-group {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.forgot-pass-text {
  color: $color-main;
  font-size: 12px;
  font-weight: bold;

  cursor: pointer;
}

.terms-text {
  font-weight: bold;
  font-size: 10px;
  color: $color-dark;
  cursor: pointer;
}

.dot {
  background: transparent url(../../assets/dot.svg) no-repeat center center;
  min-width: 5px;
  min-height: 5px;

  margin-left: $sm;
  margin-right: $sm;
}

.policy-text {
  display: flex;
  flex-direction: row;
  margin-top: $lg;
  cursor: pointer;
}

.step__container {
  width: 500px;
  display: flex;
  flex-direction: column;
}

.first-step__title {
  font-size: 18px;
  font-weight: 600;
  color: $color-dark;
  margin-bottom: $lg * 2;
}

.step__body {
  width: 100%;
  background-color: $color-grey-light;
  border: 1.5px solid $color-grey;
  border-radius: 30px;

  padding: $lg * 1.5;
}

.step_subtitle {
  color: $color-grey-dark;

  margin-bottom: $md;
}

.form__phone {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-white;
  border: 1.5px solid $color-grey !important;
  border-radius: 16px;
  height: 60px;
  outline: none;
  margin-bottom: $lg !important;
  padding-right: $md !important;

  font-weight: bold !important;
  text-decoration: none;
  font-size: 0.8rem !important;
}

.full-name__container {
  background-color: $color-white;
  border: 1px solid $color-grey;
  border-radius: 16px;

  padding-top: $md;
  padding-bottom: $md;

  margin-bottom: $lg;
}

.form__error {
  font-size: 12px;
  font-weight: 900;
  color: $color-red;

  margin-left: $lg;
}

.first-step__form {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.form__employees {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  padding: $lg * 1.5;
  background-color: $color-white;
  border: 1.5px solid $color-grey;
  border-radius: 16px;

  margin-bottom: $lg * 1.5;
}

.form__employee-title {
  font-size: 12px;
  font-weight: bold;

  margin-bottom: $lg;
}

.employee__option {
  font-size: 12px;
  font-weight: bold;

  margin-bottom: $lg + $sm;
}

.form__next {
  display: flex;
  justify-content: space-between;


  width: 100%;
  padding-right: 0;
}

.form__next-one {
  display: flex;
  align-self: flex-end;
  padding-right: 0;
}

/*--------------------*/

.second-step__title {
  //font-size: 28px;
  font-size: 18px;
  font-family: 'Inter', 'Montserrat', sans-serif;
  font-weight: 600;
  color: $color-dark;
  margin-bottom: $sm + $lg;
}

.second-step__subtitle {
  font-size: 12px;
  font-weight: bold;
  color: $color-dark;
  margin-bottom: 2 * ($sm + $lg);
}

/*---------------------*/

.not-robot {
  display: flex;
  align-self: flex-start;
  align-items: center;
  width: 60%;

  border: 1.5px solid $color-grey;
  border-radius: 16px;
  padding-left: $lg;

  background-color: $color-white;
  height: 60px;

  margin-bottom: 2 * $sm + $lg;
}

.agree-text {
  font-weight: bold;
  font-size: 12px;

  margin-bottom: $lg;
}

.agree__terms {
  cursor: pointer;
  color: $color-main;
}

.apple-icon {
  height: 70px;
  cursor: pointer;
  margin-bottom: $lg * 2;
}

// Contact us

.contact-us__page {
  width: 500px;

  .contact-us__container {
    margin-top: $lg * 1.5;
    padding: $lg * 1.5;
    background: $color-grey-light;
    border: 1px solid $color-grey;
    border-radius: $lg;

    > div:first-child {
      background: $color-white;
      border: 1px solid $color-grey;
      padding: $lg * 1.5;
      border-radius: $lg;
    }

    .actions-container {
      margin-top: $lg * 1.5;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .main-body {
    height: 92%;
    padding: 0 $md;
  }

  .login-form {
    width: 100%;
    padding: $lg + $md;
  }

  .login-text {
    margin-top: 42px;
    font-size: $md * 3;
  }

  .login-subtext {}

  .MuiInputBase-root,
  .MuiFormControl-root{
    height: 50px !important;
  }

  .btn-group {
    margin: $lg * 2 0;
    flex-direction: column;
    height: $lg * 7;

    & a, button {
      width: 100%;
      height: 50px;
    }
  }

  .forgot-pass-text {
    font-size: 14px;
  }

  .terms-text {
    font-size: $sm + $md;
  }

  .MuiPaper-root {
    flex-wrap: nowrap !important;
  }

  .step__container {
    width: 100%;
  }

  .first-step__title,
  .second-step__title {
    font-size: 18px;
    margin-bottom: 24px;
    text-align: center;
  }

  .second-step__subtitle {
    text-align: center;
  }

  .step__body {}

  .form__employees {
    margin-bottom: $lg + $sm
  }

  .form__next {
    margin-top: $lg;
    flex-direction: column;
    height: $lg * 7;
    & a, button {
      width: 100%;
      height: 50px;
    }
  }

  .contact-us__page {
    width: 100%;
    margin: 0 $lg;
  }
}

@media screen and (max-width: $screen-md) {
  .forgot-pass-text {
    font-size: 14px;
  }

  .terms-text {
    font-size: $sm + $md;
  }
}
