@import '../../styles/variables';

.user-permissions-list__container {
  background: $color-white;
  border: 1px solid $color-grey;
  border-radius: $lg;
  padding: $lg;

  > div:not(:last-child) {
  }
}

.users-page__max-users {
  margin-right: $md * 1.5;
  background: $color-white;
  border-radius: $lg;
  padding: $md $lg;
  border: 2px solid;
  height: 60px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  > div:first-child {
    text-align: center;
    font-size: 17px;
    font-weight: bold;
  }

  > div:last-child {
    text-align: center;
    font-size: 12px;
  }
}
